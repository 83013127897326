export const serialize = (data) => (
  {
    ...data,
    id: parseInt(data.id, 10),
    status: parseInt(data.status, 10) === 1,
    visible: parseInt(data.visible, 10) === 1
  }
);

export const deserialize = (data) => (
  {
    ...data,
    status: data.status ? 1 : 0,
    visible: data.visible ? 1 : 0
  }
);

