import { useMutation } from "react-query";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import { Actions, Endpoint } from "../utils/utils";

const uploadToServer = async (hash, action, serverId) => {
  const response = await axios.post(
    Endpoint,
    {
      hash,
      action,
      server_id: serverId
    }
  );
  return response.data?.results;
}

export const useUploadMeToServer = () => {
  const { GetUserInfo } = useAuth();
  const { hash } = GetUserInfo();
  return useMutation(
    (serverId) => {
      return uploadToServer(hash, Actions.addMeToServer, serverId)
    });
};
