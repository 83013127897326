import React from "react";
import { copy } from "../utils/utils";
import FileSaver from "file-saver";
import { Dropdown, Button, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useGetMyServers } from "../queries/useGetMyServers";
import { useAuth } from "../contexts/AuthContext";
import { useUploadMeToServer } from "../queries/useUploadMeToServer";
import { useQueryClient } from "react-query";
import { RDP } from "../utils/rdpFile";

const statuses = [
  'Хорошо',
  "Средне",
  "Высоко",
  "Выключен"
];

export function saveFileRDP(server, username, addUserName = false) {
  const filename = (
    addUserName ? username + " - " : ""
  ) + server.name + " - desir-savoir.rdp";
  const rdp = copy(RDP.toString()).replace("{ADDRESS}", server.address).replace("{USERNAME}", username);
  const file = new File(
    [rdp], filename, { type: "text/plain;charset=utf-8" });
  FileSaver.saveAs(file);
}

export const Server = (props) => {
  const myServers = useGetMyServers();
  const { GetUserInfo } = useAuth();
  const mutateUpload = useUploadMeToServer();
  const queryClient = useQueryClient();
  const { user } = GetUserInfo();
  const [downloadInProgress, setDownloadInProgress] = React.useState(false);
  let statusColor = "green";
  let statusStr = statuses[0];
  if (props.data.status) {
    if (parseInt(props.data.max, 10) / 2 < parseInt(props.data.users, 10)) {
      statusStr = statuses[1];
      statusColor = "orange";
    }
    if (parseInt(props.data.max, 10) - 2 <= parseInt(props.data.users, 10)) {
      statusStr = statuses[2];
      statusColor = "hot-orange";
    }
  } else {
    statusStr = statuses[3];
    statusColor = "red";
  }

  const uploadToServer = async () => {
    await mutateUpload.mutateAsync(props.data.id)
    await queryClient.invalidateQueries('myServers')
  }

  let serverExist = false;

  if (!myServers.isLoading && Array.isArray(myServers.data)) {
    serverExist = myServers.data.includes(props.data.id)
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as={Button} variant="button" id="dropdown-basic">
        <div className={"top-server-item " + statusColor}>
          Имя: {props.data.name}
          <br />
          Статус: {statusStr}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="server-content-wrapper">
        <Row
          style={{
            padding: '0 10px 0 0'
          }}
        >
          <Col
            style={{
              maxWidth: '80px',
              paddingTop: '5px',
              paddingLeft: '20px',
              textAlign: 'center',
            }}
          >
            <i className={"icons icon-server feather server-icon " + statusColor} />
          </Col>
          <Col>
            <Row className={'line'}>
              <Col>Сервер:</Col>
              <Col>{props.data.name}</Col>
            </Row>
            <Row className={'line'}>
              <Col>Нагрузка:</Col>
              <Col>{props.data.users}/{props.data.max}</Col>
            </Row>
            <Row className={'line'}>
              <Col>Статус:</Col>
              <Col>{statusStr}</Col>
            </Row>
            <Row className={'line'}>
              <Col>Система:</Col>
              <Col>{props.data.system}</Col>
            </Row>
            <Row className={'line'}>
              <Col>Могу подключиться:</Col>
              <Col
                style={{
                  color: serverExist ? "green" : "red"
                }}
              >
                {serverExist ? "Да" : "Нет"}
              </Col>
            </Row>
            <Row className={'line'}>
              <Col>Время перезагрузки:</Col>
              <Col>{props.data.reboot_time}</Col>
            </Row>
            <Row className={'line'}>
              <Col className={"button-wrapper text-end"}>
                <OverlayTrigger
                  overlay={(
                    <Tooltip>
                            <span>
                              Скачать файл конфигурации подключения
                            </span>
                    </Tooltip>
                  )}
                >
                  <i
                    className="icons icon-file-plus feather"
                    onClick={() => {
                      if (!downloadInProgress) {
                        setDownloadInProgress(true);
                        saveFileRDP(props.data, user.serverLogin);
                        setDownloadInProgress(false);
                      }
                    }}
                  />
                </OverlayTrigger>
                {!serverExist && (
                  <OverlayTrigger
                    overlay={(
                      <Tooltip>Загрузить пользователя на сервер</Tooltip>
                    )}
                  >
                    <i
                      className="icons icon-upload-cloud feather"
                      onClick={uploadToServer}
                    />
                  </OverlayTrigger>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
}
