import { useQuery } from "react-query";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import { Actions, Endpoint } from "../utils/utils";

const fetchServers = async ({ queryKey }) => {
  const [, hash] = queryKey
  const response = await axios.post(
    Endpoint,
    {
      hash,
      action: Actions.getMyServers
    }
  );
  return response.data?.results;
}

export const useGetMyServers = () => {
  const { isAuth, GetUserInfo } = useAuth();
  const { hash } = GetUserInfo();
  return useQuery(
    ["myServers", hash],
    fetchServers,
    {
      select: (data) => (
        data.status ? data.result.map((i) => (
          parseInt(i.server_id, 10)
        )) : []
      ),
      enabled: isAuth
    }
  );
};
