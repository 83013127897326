import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import { AuthProvider } from "./contexts/AuthContext";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ModalWindow } from "./contexts/Modal";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <React.Fragment>
      <Router basename={BASENAME}>
        <AuthProvider>
          <ModalWindow>
            <QueryClientProvider client={queryClient}>
              {renderRoutes(routes)}
            </QueryClientProvider>
          </ModalWindow>
        </AuthProvider>
      </Router>
    </React.Fragment>
  );
};

export default App;
