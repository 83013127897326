import { Paths } from "./utils/utils";

const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Навигация',
      type: 'group',
      icon: 'icon-navigation',
      access: [1, 2, 3, 4],
      children: [
        {
          id: 'dashboard',
          title: 'Статистика',
          type: 'item',
          access: [1, 2, 3, 4],
          url: Paths.dashboard,
          icon: 'feather icon-pie-chart',
        }
      ]
    },
    {
      id: 'administration',
      title: 'Администрирование',
      type: 'group',
      icon: 'icon-navigation',
      access: [1, 2, 4],
      children: [
        {
          id: 'servers',
          title: 'Сервера',
          type: 'collapse',
          access: [1, 2, 4],
          url: Paths.servers,
          icon: 'feather icon-server',
          children: [
            {
              id: 'serverList',
              title: 'Список',
              type: 'item',
              access: [1, 2, 4],
              url: Paths.servers
            },
            {
              id: 'serverAdd',
              access: [1, 2],
              title: 'Добавить',
              type: 'item',
              url: Paths.serversEdit.replace(":id", "new")
            }
          ]
        },
        {
          id: 'users',
          title: 'Пользователи',
          type: 'collapse',
          access: [1, 2],
          url: Paths.users,
          icon: 'feather icon-users',
          children: [
            {
              id: 'userList',
              title: 'Список',
              access: [1, 2],
              type: 'item',
              url: Paths.users
            },
            {
              id: 'userAdd',
              title: 'Добавить',
              access: [1, 2],
              type: 'item',
              url: Paths.userEdit.replace(":id", "new")
            }
          ]
        },
        {
          id: 'notifications',
          title: 'Уведомления',
          access: [1, 2],
          type: 'collapse',
          url: Paths.notifications,
          icon: 'feather icon-help-circle',
          children: [
            {
              id: 'notificationList',
              title: 'Список',
              access: [1, 2],
              type: 'item',
              url: Paths.notifications
            },
            {
              id: 'notificationAdd',
              title: 'Добавить',
              access: [1, 2],
              type: 'item',
              url: Paths.notificationAdd
            }
          ]
        },
        {
          id: 'allStatistics',
          title: 'Полная статистика',
          access: [1, 2],
          type: 'item',
          url: Paths.allStatistics,
          icon: 'feather icon-pie-chart',
        },
        {
          id: 'shops',
          title: 'Магазины',
          access: [1, 2],
          type: 'item',
          url: Paths.shops,
          icon: 'feather icon-shopping-cart',
        },
        {
          id: 'reports',
          title: 'Отчеты',
          access: [1, 2],
          type: 'item',
          url: Paths.reports,
          icon: 'feather icon-book',
        },
        {
          id: 'goods',
          title: 'Товары',
          access: [1, 2],
          type: 'item',
          url: Paths.goods,
          icon: 'feather icon-tv',
        },
        {
          id: 'errors',
          title: 'Ошибки',
          access: [1, 2],
          type: 'item',
          url: Paths.errors,
          icon: 'feather icon-alert-circle',
        }
      ]
    }
  ]
};

export default menuItems;
