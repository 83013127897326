import { useMutation } from "react-query";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import { Actions, Endpoint } from "../utils/utils";

const deleteNotification = async (hash, action, notification_id) => {
  const response = await axios.post(
    Endpoint,
    {
      hash,
      action,
      notification_id
    }
  );
  return response.data?.results;
}

export const useDeleteNotification = () => {
  const { GetUserInfo } = useAuth();
  const { hash } = GetUserInfo();
  return useMutation(
    (notification_id)=>{
      return deleteNotification(hash, Actions.deleteNotification, notification_id)
  });
};
