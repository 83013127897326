import axios from "axios";
import React from "react";
import { STORAGE, Endpoint, Actions, PingTimeout } from "../utils/utils";

const AuthContext = React.createContext({
  Login: async () => {
    // predefine
  },
  Logout: () => {
    // predefine
  },
  GetUserInfo: () => {
    // predefine
  },
  CheckAuth: () => {
    //predefine
  },
  isLoading: false,
  isAuth: false
});

let interval = null;

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuth, setIsAuth] = React.useState(false);
  const [time, setTime] = React.useState(0);

  const GetUserInfo = () => {
    let hash = "";
    let user = "";
    if (isAuth) {
      try {
        hash = localStorage.getItem(STORAGE.hash);
        user = JSON.parse(localStorage.getItem(STORAGE.user))
      } catch (e) {
        console.log(e)
      }
    }
    return {
      hash,
      user,
    };
  };

  React.useEffect(() => {
    if (time !== 0) {
      CheckAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const CheckAuth = async () => {
    const { hash } = GetUserInfo();
    if (hash) {
      try {
        const response = await axios.post(
          Endpoint,
          {
            hash,
            action: Actions.ping
          }
        );
        if (response?.data?.results?.status) {
          setIsAuth(true);
          return true;
        }
      } catch (e) {
        console.log("check auth error", e);
      }
    }
    localStorage.removeItem(STORAGE.hash);
    localStorage.removeItem(STORAGE.user);
    clearInterval(interval);
    setIsAuth(false);
    return false;
  }

  const Login = async (login, password) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        Endpoint,
        {
          login: login,
          password: password,
          action: Actions.login
        }
      );
      if (response?.data?.results?.status) {
        const { hash, user } = response.data.results;
        localStorage.setItem(STORAGE.hash, hash);
        localStorage.setItem(STORAGE.user, JSON.stringify(user));
        setIsAuth(true);
        interval = setInterval(() => {
          setTime(time + 1);
        }, PingTimeout);
        setIsLoading(false);
        return;
      }
      setIsAuth(false);
    } catch (e) {
      localStorage.removeItem(STORAGE.hash);
      localStorage.removeItem(STORAGE.user);
      clearInterval(interval);
      setIsAuth(false);
    }
    setIsLoading(false);
  };

  const Logout = () => {
    localStorage.removeItem(STORAGE.hash);
    localStorage.removeItem(STORAGE.user);
    setIsAuth(false);
  };

  React.useEffect(() => {
    const hash = localStorage.getItem(STORAGE.hash);
    if (hash) {
      axios.post(
        Endpoint,
        {
          hash,
          action: Actions.ping
        }
      ).then((response) => {
        if (response?.data?.results?.status) {
          setIsAuth(true);
          interval = setInterval(() => {
            setTime(time + 1);
          }, PingTimeout);
        }
        setIsLoading(false);
      }, (e) => {
        console.log("restore check auth error", e);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        Logout,
        Login,
        GetUserInfo,
        isAuth,
        isLoading,
        CheckAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => React.useContext(AuthContext);
