import { useQuery } from "react-query";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import { Actions, Endpoint } from "../utils/utils";
import { serialize } from "../serializers/server";

const fetchServers = async ({ queryKey }) => {
  const [, hash] = queryKey
  const response = await axios.post(
    Endpoint,
    {
      hash,
      action: Actions.getServers
    }
  );
  return response.data?.results;
}

export const useGetServers = () => {
  const { isAuth, GetUserInfo } = useAuth();
  const { hash } = GetUserInfo();
  return useQuery(
    ["servers", hash],
    fetchServers,
    {
      select: (data) => (
        data.status ? data.result.map(serialize) : []
      ),
      enabled: isAuth
    }
  );
};
