import React from 'react';
import { ListGroup } from 'react-bootstrap';

import useWindowSize from '../../../../hooks/useWindowSize';
import { useGetServers } from "../../../../queries/useGetServers";
import { Server } from "../../../../components/Server";

const NavLeft = () => {
  const windowSize = useWindowSize();

  let navItemClass = ['nav-item'];
  if (windowSize.width <= 575) {
    navItemClass = [...navItemClass, 'd-none'];
  }

  const servers = useGetServers();

  return (
    <React.Fragment>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav mr-auto">
        {(
          !servers.isLoading && Array.isArray(servers.data)
        ) && servers.data.map((item, index) => {
          if (!item.visible) {
            return null;
          }
          return (
            <ListGroup.Item
              as="li"
              key={index}
              bsPrefix=" "
              className={navItemClass.join(' ')}
            >
              <Server
                data={item}
              />
            </ListGroup.Item>
          )
        })}
      </ListGroup>
    </React.Fragment>
  );
};

export default NavLeft;
