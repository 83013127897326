import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import { BASE_URL } from './config/constant';
import { Paths } from "./utils/utils";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: Paths.login,
    component: lazy(() => import('./views/auth/signin/SignIn'))
  },
  {
    exact: true,
    path: Paths.register,
    component: lazy(() => import('./views/auth/signup/SignUp'))
  },
  {
    path: '*',
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: Paths.dashboard,
        component: lazy(() => import('./views/statistics'))
      },
      {
        exact: true,
        path: Paths.servers,
        component: lazy(() => import('./views/servers'))
      },
      {
        exact: true,
        path: Paths.serversEdit,
        component: lazy(() => import('./views/servers/edit'))
      },
      {
        exact: true,
        path: Paths.users,
        component: lazy(() => import('./views/users'))
      },
      {
        exact: true,
        path: Paths.goods,
        component: lazy(() => import('./views/goods'))
      },
      {
        exact: true,
        path: Paths.userEdit,
        component: lazy(() => import('./views/users/edit'))
      },
      {
        exact: true,
        path: Paths.profile,
        component: lazy(() => import('./views/profile'))
      },
      {
        exact: true,
        path: Paths.settings,
        component: lazy(() => import('./views/settings'))
      },
      {
        exact: true,
        path: Paths.notifications,
        component: lazy(() => import('./views/notifications/index'))
      },
      {
        exact: true,
        path: Paths.notificationAdd,
        component: lazy(() => import('./views/notifications/add'))
      },
      {
        exact: true,
        path: Paths.allStatistics,
        component: lazy(() => import('./views/allStatistics'))
      },
      {
        exact: true,
        path: Paths.shops,
        component: lazy(() => import('./views/shops'))
      },
      {
        exact: true,
        path: Paths.reports,
        component: lazy(() => import('./views/reports'))
      },
      {
        exact: true,
        path: Paths.errors,
        component: lazy(() => import('./views/errors'))
      },
      {
        exact: true,
        path: '/basic/button',
        component: lazy(() => import('./views/ui-elements/basic/BasicButton'))
      },
      {
        exact: true,
        path: '/basic/badges',
        component: lazy(() => import('./views/ui-elements/basic/BasicBadges'))
      },
      {
        exact: true,
        path: '/basic/breadcrumb',
        component: lazy(() => import('./views/ui-elements/basic/BasicBreadcrumb'))
      },
      {
        exact: true,
        path: '/basic/pagination',
        component: lazy(() => import('./views/ui-elements/basic/BasicPagination'))
      },
      {
        exact: true,
        path: '/basic/collapse',
        component: lazy(() => import('./views/ui-elements/basic/BasicCollapse'))
      },
      {
        exact: true,
        path: '/basic/tabs-pills',
        component: lazy(() => import('./views/ui-elements/basic/BasicTabsPills'))
      },
      {
        exact: true,
        path: '/basic/typography',
        component: lazy(() => import('./views/ui-elements/basic/BasicTypography'))
      },
      {
        exact: true,
        path: '/forms/form-basic',
        component: lazy(() => import('./views/forms/FormsElements'))
      },
      {
        exact: true,
        path: '/tables/bootstrap',
        component: lazy(() => import('./views/tables/BootstrapTable'))
      },
      {
        exact: true,
        path: '/charts/nvd3',
        component: lazy(() => import('./views/charts/nvd3-chart'))
      },
      {
        exact: true,
        path: '/sample-page',
        component: lazy(() => import('./views/extra/SamplePage'))
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />
      }
    ]
  }
];

export default routes;
