import { Button, Modal } from 'react-bootstrap';
import React from 'react';

export const ModalContext = React.createContext({
  showPopup: () => {
    // predefined
  }
});

const ResetComponent = () => (
  <div />
)

const defaultProps = {
  title: "",
  onSave: () => {
  },
  showSave: true,
  defaultBuffer: {},
  content: false,
  show: false
}

export function ModalWindow({ children }) {
  const [propsModal, setPropsModal] = React.useState(defaultProps);
  const { show, onSave, content, title, showSave, defaultBuffer } = propsModal;
  const [buffer, setBuffer] = React.useState({ ...defaultBuffer });
  const [validation, setValidation] = React.useState(() => () => true);

  const handleClose = () => {
    setPropsModal(defaultBuffer)
  }

  React.useEffect(() => {
    if (!show) {
      setValidation(() => () => true);
      setBuffer({ ...defaultBuffer });
      setPropsModal(defaultProps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const Component = content ? content : ResetComponent;

  return (
    <ModalContext.Provider
      value={{
        showPopup: (props) => {
          setBuffer(props.defaultBuffer);
          setPropsModal({ ...props })
        }
      }}
    >
      {children}
      {show && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Component
              setBuffer={setBuffer}
              buffer={buffer}
              setValidation={setValidation}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {showSave ? "Отмена" : "Закрыть"}
            </Button>
            {showSave && (
              <Button
                variant="primary"
                onClick={() => {
                  const result = validation(buffer);
                  if (result) {
                    onSave(buffer);
                    handleClose();
                  }
                }}
              >
                Сохранить
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </ModalContext.Provider>
  );
}

export const useModal = () => React.useContext(ModalContext);
