import React, { useContext } from 'react';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { ConfigContext } from '../../../../contexts/ConfigContext';
import { useAuth } from "../../../../contexts/AuthContext";

import { useGetNotifications } from "../../../../queries/useGetNotifications";
import { useMarkAsReadNotification } from "../../../../queries/useMarkReadNotification";
import { useQueryClient } from "react-query";
import { useDeleteNotification } from "../../../../queries/useDeleteNotification";
import { Avatars } from "../../../../utils/utils";

const NavRight = () => {
  const configContext = useContext(ConfigContext);
  const { Logout, GetUserInfo } = useAuth();
  const { rtlLayout } = configContext.state;
  const { data } = useGetNotifications();
  const mutation = useMarkAsReadNotification();
  const deleteMutation = useDeleteNotification();

  const handleLogout = async () => {
    try {
      // handleClose();
      Logout();
    } catch (err) {
      console.error(err);
    }
  };

  const { user } = GetUserInfo();
  const avatar = Avatars[user.avatar || 1];
  const notifications = [];
  const oldNotification = []
  if (Array.isArray(data)) {
    data.forEach((item) => {
      if (item.read) {
        oldNotification.push(item);
      } else {
        notifications.push(item);
      }
    })
  }

  const queryClient = useQueryClient();

  const markAsRead = async (id) => {
    await mutation.mutateAsync(id)
    await queryClient.invalidateQueries('notifications')
  }

  const deleteItem = async (id) => {
    await deleteMutation.mutateAsync(id)
    await queryClient.invalidateQueries('notifications')
  }

  return (
    <React.Fragment>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto" id="navbar-right">
        {Array.isArray(data) && data.length > 0 && (
          <ListGroup.Item as="li" bsPrefix=" ">
            <Dropdown alignRight={!rtlLayout}>
              <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
                <i className="feather icon-bell icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu alignRight className="notification notification-scroll">
                <div className="noti-head">
                  <h6 className="d-inline-block m-b-0">Уведомления</h6>
                </div>
                <PerfectScrollbar>
                  <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">
                    {notifications.length > 0 && (
                      <>
                        <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                          <p className="m-b-0">НОВЫЕ</p>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="notification">
                          {notifications.map((item, index) => (
                            <Media
                              key={index}
                              onClick={() => markAsRead(item.id)}
                            >
                              <i
                                className={"icon icon-message-circle feather"}
                                style={{
                                  fontSize: "40px",
                                  paddingRight: "5px"
                                }}
                              />
                              <Media.Body>
                                <p>
                                  <b>Система</b>
                                  <span className="n-time text-muted">
                                  <i
                                    className="icon feather icon-x m-r-10"
                                    style={{
                                      cursor: "pointer"
                                    }}
                                    onClick={() => deleteItem(item.id)}
                                  />
                                </span>
                                </p>
                                <p>{item.message}</p>
                              </Media.Body>
                            </Media>
                          ))}
                        </ListGroup.Item>
                      </>
                    )}
                    {oldNotification.length > 0 && (
                      <>
                        <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                          <p className="m-b-0">РАНЕЕ</p>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" bsPrefix=" " className="notification">
                          {oldNotification.map((item, index) => (
                            <Media key={index}>
                              <i
                                className={"icon icon-message-circle feather"}
                                style={{
                                  fontSize: "40px",
                                  paddingRight: "5px"
                                }}
                              />
                              <Media.Body>
                                <p>
                                  <b>Система</b>
                                  <span className="n-time text-muted">
                                  <i
                                    className="icon feather icon-x m-r-10"
                                    style={{
                                      cursor: "pointer"
                                    }}
                                    onClick={() => deleteItem(item.id)}
                                  />
                                </span>
                                </p>
                                <p>{item.message}</p>
                              </Media.Body>
                            </Media>
                          ))}
                        </ListGroup.Item>
                      </>
                    )}
                  </ListGroup>
                </PerfectScrollbar>
              </Dropdown.Menu>
            </Dropdown>
          </ListGroup.Item>
        )}
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              <i className="icon feather icon-settings" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head">
                <img src={avatar} className="img-radius" alt="User Profile" />
                <span>{user.name}</span>
                <Link
                  to="#"
                  className="dud-logout"
                  onClick={handleLogout}
                  title="Logout"
                >
                  <i className="feather icon-log-out" />
                </Link>
              </div>
              <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body">
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="/settings" className="dropdown-item">
                    <i className="feather icon-settings" /> Settings
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="/profile" className="dropdown-item">
                    <i className="feather icon-user" /> Profile
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item" onClick={handleLogout}>
                    <i className="feather icon-log-out" /> Logout
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
};

export default NavRight;
