import avatar1 from '../assets/images/user/avatar-1.jpg';
import avatar2 from '../assets/images/user/avatar-2.jpg';
import avatar3 from '../assets/images/user/avatar-3.jpg';
import avatar4 from '../assets/images/user/avatar-4.jpg';
import avatar5 from '../assets/images/user/avatar-5.jpg';
import moment from "moment";

export const Paths = {
  login: '/login',
  dashboard: '/dashboard',
  servers: '/servers',
  serversEdit: '/servers/edit/:id',
  userEdit: '/users/edit/:id',
  users: '/users',
  statistics: '/statistics',
  profile: '/profile',
  settings: '/settings',
  allStatistics: '/all-statistics',
  notifications: '/notifications',
  shops: '/shops',
  errors: '/errors',
  reports: '/reports',
  goods: '/goods',
  notificationAdd: '/notifications/add',
  register: '/register',
};

export const STORAGE = {
  hash: 'HASH',
  user: 'USER'
};

export function copy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const PingTimeout = 1000 * 60 * 30;

export const Actions = {
  login: "login",
  ping: "ping",
  getServers: "get-servers",
  getMyServers: "get-my-servers",
  addServer: "add-server",
  editServer: "update-server",
  deleteServer: "delete-server",
  restartServer: "restart-server",
  wolServer: "wol-server",
  getUsers: "get-users",
  addUser: "add-user",
  editUser: "edit-user",
  getAllNotifications: "get-all-notifications",
  getNotifications: "get-notification",
  addNotification: "add-notifications",
  markAsRead: "mark-as-read",
  markBanned: "mark-as-banned",
  markShopBanned: "mark-shop-as-banned",
  deleteNotification: "delete-notification",
  getStatistics: "get-statistics",
  getAllStatistics: "get-all-statistics",
  markShopPaid: "mark-as-paid",
  addUserToServer: "add-user-to-server",
  addMeToServer: "add-to-server",
  registerUser: "register-user",
  editShop: "edit-shop",
  getErrors: "get-errors",
  deleteError: "delete-error",
  changeUserPassword: "change-user-password"
}


export const Avatars = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5
];

export const formatEtsyNumber = (value, useSpecialFormat = false) => {
  if (useSpecialFormat) {
    return (
      value / 100
    ).toString().replace(".", ",");
  }
  return value / 100;
}

export const mergeShops = (arr) => {
  const result = {
    id: "all",
    name: "All",
    sellCount: 0,
    payoutsGross: 0,
    payoutsNet: 0,
    payouts: [],
    balanceOnStart: 0,
    balanceOnEnd: 0,
    entities: []
  };

  if (Array.isArray(arr)) {
    arr.forEach((item) => {
      result.sellCount += item.sellCount;
      result.payoutsGross += item.payoutsGross;
      result.payoutsNet += item.payoutsNet;
      result.balanceOnStart += item.balanceOnStart;
      result.balanceOnEnd += item.balanceOnEnd;
      result.payouts = [...result.payouts, ...item.payouts];
      result.entities = [...result.entities, ...item.entities];
    })
  }
  result.entities = result.entities.sort((a, b) => b.sequenceNumber - a.sequenceNumber)
  return result;
}

export const MinDate = moment("01.08.2022", "DD.MM.YYYY")

export const Endpoint = 'https://profile.desir-savoir.com/server/api.php';

// eslint-disable-next-line new-parens
export const uniqBy = (arr, fn, set = new Set) => arr.filter(el => (
  v => !set.has(v) && set.add(v)
)(typeof fn === "function" ? fn(el) : el[fn]));

